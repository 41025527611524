var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details-page"},[_c('el-breadcrumb',{staticClass:"bread-crumb",attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_c('span',{on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("首页")])]),_c('el-breadcrumb-item',[_c('span',{on:{"click":function($event){return _vm.$router.push(
            ("/courlist?tid=" + (_vm.basicInfo.type_id) + "&tn=" + (_vm.basicInfo.type_name))
          )}}},[_vm._v(_vm._s(_vm.basicInfo.type_name ? _vm.basicInfo.type_name : "档案课程"))])]),_c('el-breadcrumb-item',[_c('span',{staticStyle:{"color":"#333"}},[_vm._v(_vm._s(_vm.courseTitle))])])],1),_c('div',[_c('div',{staticClass:"details details-title"},[_c('div',{staticClass:"title-img"},[(_vm.basicInfo.pic)?_c('img',{attrs:{"src":'https://tot.xlteacher.cn' + _vm.basicInfo.pic,"alt":""}}):_vm._e()]),_c('div',{staticClass:"title-info"},[_c('p',[_vm._v(_vm._s(_vm.basicInfo.title))]),_c('p',[_vm._v(" 课时："+_vm._s(_vm.basicInfo.class_hour)+"个 时长："+_vm._s(_vm.basicInfo.video_time)+"小时 ")])]),_c('div',{staticClass:"title-progress"},[_c('div',{staticClass:"progress-pro"},[_c('span',[_vm._v("已学"+_vm._s(_vm.videoProgress.study_rate)+"%：")]),_c('el-progress',{attrs:{"percentage":_vm.videoProgress.study_rate,"show-text":false}})],1),_c('div',{staticClass:"progress-btns"},[(_vm.basicInfo.is_collection === 0)?_c('button',{staticClass:"collection",on:{"click":_vm.getCollect}},[_vm._v("  收藏 ")]):_vm._e(),(_vm.basicInfo.is_collection === 1)?_c('button',{staticClass:"collection",staticStyle:{"background-position":"13% -40%"},on:{"click":_vm.cancleCollect}},[_vm._v("  取消收藏 ")]):_vm._e(),(_vm.videoProgress.last_video_id != 0)?_c('button',{staticClass:"now-see",on:{"click":_vm.continuePlay}},[_vm._v(" 继续观看 ")]):_vm._e(),(_vm.videoProgress.last_video_id == 0)?_c('button',{staticClass:"now-see",on:{"click":_vm.nowPlay}},[_vm._v(" 立即观看 ")]):_vm._e()])])]),_c('div',{staticClass:"details details-directory"},[_c('b',{staticClass:"course-catalog-text"},[_vm._v("课程目录")]),_c('ul',[_vm._l((_vm.videoList),function(video){return _c('li',{key:video.id,style:({
            backgroundPositionY:
              video.finish_video === 0
                ? '120%'
                : video.finish_video === 1
                ? '-20%'
                : '50%',
          }),on:{"click":function($event){return _vm.toVideo(video.id)}}},[_c('img',{attrs:{"src":require("../assets/images/video_player.png"),"alt":""}}),_c('span',{staticClass:"chapter-number"},[_vm._v(_vm._s(video.video_num))]),_c('span',{staticClass:"chapter-name"},[_vm._v(" "+_vm._s(video.title)+" ")]),_c('span',{staticClass:"chapter-time"},[_vm._v("（"+_vm._s(_vm.formatSeconds(video.video_time))+"）")]),(_vm.videoProgress.last_video_id === video.id)?_c('i',{staticClass:"chapter-nearly"},[_vm._v("最近学习")]):_vm._e()])}),_c('li',{style:({
            backgroundPositionY: _vm.see === 0 ? '120%' : '-20%',
          }),on:{"click":_vm.goExam}},[_c('img',{attrs:{"src":require("../assets/images/exam.png"),"alt":""}}),_c('span',{staticClass:"chapter-exam"},[_vm._v("去考试 "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.see == 1),expression:"see == 1"}],staticClass:"chapter-nearly"},[_vm._v("已考试")])])])],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }